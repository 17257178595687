// This file contains the "Autopilot snippet" with a few tweaks:
// 1. it's been wrapped in a function so that it can be run lazilly
// 2. the "t" variable has been initialised using an environment-specific
//    variable (rather than being hardcoded)
export default function autopilot(AUTOPILOT_API_CODE, ORTTO_API_CODE) {
  (function(o) {
    var b = "https://niblewren.co/anywhere/",
      t = AUTOPILOT_API_CODE,
      a = (window.AutopilotAnywhere = {
        _runQueue: [],
        run: function() {
          this._runQueue.push(arguments);
        },
      }),
      c = encodeURIComponent,
      s = "SCRIPT",
      d = document,
      l = d.getElementsByTagName(s)[0],
      p =
        "t=" +
        c(d.title || "") +
        "&u=" +
        c(d.location.href || "") +
        "&r=" +
        c(d.referrer || ""),
      j = "text/javascript",
      z,
      y;
    if (!window.Autopilot) window.Autopilot = a;
    if (o.app) p = "devmode=true&" + p;
    z = function(src, asy) {
      var e = d.createElement(s);
      e.src = src;
      e.type = j;
      e.async = asy;
      l.parentNode.insertBefore(e, l);
    };
    y = function() {
      z(b + t + "?" + p, true);
    };
    y();
  })({ app: true });

  (function(){
    window.ap3c = window.ap3c || {};
    var ap3c = window.ap3c;
    ap3c.cmd = ap3c.cmd || [];
    ap3c.cmd.push(function() {
        ap3c.init(ORTTO_API_CODE, 'https://capture-api.autopilotapp.com/');
        ap3c.track({v: 0});
    });
    ap3c.activity = function(act) { ap3c.act = (ap3c.act || []); ap3c.act.push(act); };
    var s, t; s = document.createElement('script'); s.type = 'text/javascript'; s.src = "https://cdn3l.ink/app.js";
    t = document.getElementsByTagName('script')[0]; t.parentNode.insertBefore(s, t);
  })()
}
